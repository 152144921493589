<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 년도 -->
          <c-datepicker
            name="year"
            type="year"
            label="점검년도"
            v-model="searchParam.year"
          ></c-datepicker>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-equip
            label="설비"
            type="search"
            name="equipmentCd"
            v-model="searchParam.equipmentCd">
          </c-equip>
        </div>
          <!-- 계획/무계획 -->
        <!-- <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="checkNoPlanItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="noPlanResultEnrollFlag"
            label="계획/무계획"
            v-model="searchParam.noPlanResultEnrollFlag"
          ></c-select>
        </div> -->
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검/정비결과 -->
          <c-select
            type="search"
            codeGroupCd="MIM_CHECK_RESULT_CD"
            itemText="codeName"
            itemValue="code"
            name="resultCd"
            label="점검/정비결과"
            v-model="searchParam.resultCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검일 -->
          <c-datepicker
            :range="true"
            name="period"
            label="실제 점검/정비일"
            v-model="period"
          ></c-datepicker>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검/정비 -->
          <c-radio
            :editable="editable"
            :comboItems="typeItems"
            label="점검/정비"
            name="type"
            v-model="type">
          </c-radio>
        </div>
        <div v-show="type === '2'" class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검유형 -->
          <c-select
            type="search"
            codeGroupCd="MDM_CHECK_KIND_CD"
            itemText="codeName"
            itemValue="code"
            name="safCheckTypeCd1"
            label="점검유형"
            v-model="searchParam.safCheckTypeCd1"
          ></c-select>
        </div>
        <div v-show="type === '3'" class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 정비유형 -->
          <c-select
            type="search"
            codeGroupCd="MDM_MAINTENANCE_KIND_CD"
            itemText="codeName"
            itemValue="code"
            name="safCheckTypeCd2"
            label="정비유형"
            v-model="searchParam.safCheckTypeCd2"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="설비점검/정비 현황 목록"
      tableId="equipmentCd"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group>
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import CDatepicker from '../../../../components/CDatepicker.vue';
export default {
  components: { CDatepicker },
  name: "equipment-inspection",
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: "plantNm" },
          { index: 1, colName: "equipmentCdName"},
          ],
        columns: [
          {
            name: "plantNm",
            field: "plantNm",
            label: "사업장",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
          {
            name: "equipmentCdName",
            field: "equipmentCdName",
            label: "설비명(코드)",
            style: 'width:150px',
            align: "center",
            sortable: true,
            type: "link",
          },
          {
            name: "statusTypeCd",
            field: "statusTypeCd",
            label: "현황분류",
            style: 'width:80px',
            type: 'tag',
            colorItems: [
              { stepperMstCd: 'Y', stepperMstNm: '점검', colorClass: 'green' },
              { stepperMstCd: 'N', stepperMstNm: '정비', colorClass: 'orange' },
            ],
            align: "center",
            sortable: true,
          },
          {
            name: "deptName",
            field: "deptName",
            label: "점검부서/정비입회자",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "typeName",
            field: "typeName",
            label: "점검/정비 유형",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "cycleName",
            field: "cycleName",
            label: "주기",
            style: 'width:50px',
            align: "center",
            sortable: true,
          },
          {
            label: '점검/정비',
            align: 'center',
            sortable: false,
            child: [
              {
                name: "realDate",
                field: "realDate",
                label: "실제일",
                style: 'width:80px',
                align: "center",
                sortable: true,
              },
              {
                name: "userName",
                field: "userName",
                label: "실제자",
                style: 'width:80px',
                align: "center",
                sortable: true,
              },
              {
                name: "resultName",
                field: "resultName",
                label: "결과",
                style: 'width:60px',
                align: "center",
                sortable: true,
              },
            ]
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            label: "관련법규",
            style: 'width:200px',
            align: "left",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        // 설비점검 start
        safCheckTypeCd1: null,
        // 설비정비 start
        safCheckTypeCd2: null, 
        resultCd: null,
        startYmd: '',
        endYmd: '',
        noPlanResultEnrollFlag: null,
      },
      editable: true,
      period: ['',''],
      type: '1',
      checkNoPlanItems: [
        { code: 'Y', codeName: '무계획' },
        { code: 'N', codeName: '계획' }
      ], // 점검진행상태
      typeItems: [
        { code: '1', codeName: '전체' },
        { code: '2', codeName: '점검' },
        { code: '3', codeName: '정비' },
      ],
      searchUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      listUrl: '',
    };
  },
  watch: {
    'type'() {
      if (this.type === '2') {
        this.searchParam.safCheckTypeCd2 = null;
      } else if (this.type === '3') {
        this.searchParam.safCheckTypeCd1 = null;
      } else {
        this.searchParam.safCheckTypeCd1 = null;
        this.searchParam.safCheckTypeCd2 = null;
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.listUrl = selectConfig.sop.min.equipment.maintain.list.url;
      // url setting
      // code setting
      // list setting
      this.searchParam.year = this.$comm.getThisYear();
      this.getList();
    },
    getList() {
      this.searchParam.startYmd = this.period[0];
      this.searchParam.endYmd = this.period[1];
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.target = () =>
      import(`${"./equipmentRecord.vue"}`);
      this.popupOptions.title = "설비점검/정비 현황"; // 설비점검/정비 현황
      this.popupOptions.param = {
        equipmentCd: row.equipmentCd,
        year: this.searchParam.year,
      },
      this.popupOptions.visible = true;
      // this.popupOptions.width = '95%';
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    btnCancel() {
      this.isResult = false;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
